var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"col-md-5"},[_c('v-card',[_c('v-card-title',[_c('v-toolbar-title',[_vm._v("Profiil")])],1),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('form',{staticClass:"mt-10",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('validation-provider',{attrs:{"name":"Nimi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Nimi","required":"","outlined":""},model:{value:(_vm.profile.name),callback:function ($$v) {_vm.$set(_vm.profile, "name", $$v)},expression:"profile.name"}})]}}],null,true)}),_c('v-btn',{staticClass:"mt-3",attrs:{"type":"submit","color":"secondary","tile":""}},[_vm._v("Salvesta")])],1)]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-md-5"},[_c('v-card',[_c('v-card-title',[_c('v-toolbar-title',[_vm._v("Avatar")])],1),_c('v-card-text',[(_vm.profile.id)?_c('v-img',{staticStyle:{"float":"right"},attrs:{"width":100,"height":100,"aspect-ratio":"1","src":'/api/users/' + _vm.profile.id + '/avatar/'}}):_vm._e(),_c('p',[_vm._v("Kui lisad uue avatari, siis asendatakse see olemasolevaga. Pildi maksimaalne suurus on 2MB ning see peab olema jpg või png formaadis.")]),_c('div',{staticStyle:{"clear":"both"}}),_c('validation-observer',{ref:"avatarObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('form',{staticClass:"mt-10",on:{"submit":function($event){$event.preventDefault();return _vm.avatarSubmit($event)}}},[_c('ValidationProvider',{attrs:{"name":"photos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"dense":"","outlined":"","label":"Foto","show-size":"","counter":"","error-messages":errors},model:{value:(_vm.avatarForm.files),callback:function ($$v) {_vm.$set(_vm.avatarForm, "files", $$v)},expression:"avatarForm.files"}})]}}],null,true)}),_c('v-btn',{staticClass:"mt-3",attrs:{"type":"submit","color":"secondary","tile":""}},[_vm._v("Salvesta")])],1)]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-md-5"},[_c('v-card',[_c('v-card-title',[_c('v-toolbar-title',[_vm._v("Muuda parooli")])],1),_c('v-card-text',[_c('validation-observer',{ref:"avatarObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('form',{staticClass:"mt-10",on:{"submit":function($event){$event.preventDefault();return _vm.passwordSubmit($event)}}},[_c('ValidationProvider',{attrs:{"name":"Uus parool"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","error-messages":errors,"label":"Uus parool","required":"","outlined":""},model:{value:(_vm.passwordForm.password),callback:function ($$v) {_vm.$set(_vm.passwordForm, "password", $$v)},expression:"passwordForm.password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Parooli kordus"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","error-messages":errors,"label":"Parooli kordus","required":"","outlined":""},model:{value:(_vm.passwordForm.passwordRepeat),callback:function ($$v) {_vm.$set(_vm.passwordForm, "passwordRepeat", $$v)},expression:"passwordForm.passwordRepeat"}})]}}],null,true)}),_c('v-btn',{staticClass:"mt-3",attrs:{"type":"submit","color":"secondary","tile":""}},[_vm._v("Muuda")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }