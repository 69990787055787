<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-md-5">
        <v-card>
          <v-card-title>
            <v-toolbar-title>Profiil</v-toolbar-title>
          </v-card-title>
          <v-card-text>
            <validation-observer ref="observer" v-slot="{}">
              <form class="mt-10" @submit.prevent="submit">
                <validation-provider  v-slot="{ errors }" name="Nimi" rules="required">
                  <v-text-field v-model="profile.name" :error-messages="errors" label="Nimi" required outlined />
                </validation-provider>
                <v-btn class="mt-3" type="submit" color="secondary" tile>Salvesta</v-btn>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-md-5">
        <v-card>
          <v-card-title>
            <v-toolbar-title>Avatar</v-toolbar-title>
          </v-card-title>
          <v-card-text>
            <v-img
              style="float:right;"
              v-if="profile.id"
              :width="100"
              :height="100"
              aspect-ratio="1"
              :src="'/api/users/' + profile.id + '/avatar/'"
            />
            <p>Kui lisad uue avatari, siis asendatakse see olemasolevaga. Pildi maksimaalne suurus on 2MB ning see peab olema jpg või png formaadis.</p>
            <div style="clear:both;"></div>
            <validation-observer ref="avatarObserver" v-slot="{}">
              <form class="mt-10" @submit.prevent="avatarSubmit">
                 <ValidationProvider v-slot="{ errors }" name="photos">
                  <v-file-input
                    dense
                    outlined
                    v-model="avatarForm.files"
                    label="Foto"
                    show-size
                    counter
                    :error-messages="errors"
                  />
                </ValidationProvider>
                <v-btn class="mt-3" type="submit" color="secondary" tile>Salvesta</v-btn>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-md-5">
        <v-card>
          <v-card-title>
            <v-toolbar-title>Muuda parooli</v-toolbar-title>
          </v-card-title>
          <v-card-text>
            <validation-observer ref="avatarObserver" v-slot="{}">
              <form class="mt-10" @submit.prevent="passwordSubmit">
                <ValidationProvider v-slot="{ errors }" name="Uus parool">
                  <v-text-field type="password" v-model="passwordForm.password" :error-messages="errors" label="Uus parool" required outlined />
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" name="Parooli kordus">
                  <v-text-field type="password" v-model="passwordForm.passwordRepeat" :error-messages="errors" label="Parooli kordus" required outlined />
                </ValidationProvider>
                <v-btn class="mt-3" type="submit" color="secondary" tile>Muuda</v-btn>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {},
  data () {
    return {
      isDataLoading: false,
      profile: {
        id: null,
        name: null
      },
      avatarForm: {
        files: []
      },
      passwordForm: {
        password: '',
        passwordRepeat: ''
      }
    }
  },
  computed: {},
  methods: {
    ...mapActions(['showNotification']),
    submit () {
      this.$http.put('/api/users/me', {
        name: this.profile.name
      }).then(() => {
        this.loadData()
        this.showNotification({ message: 'Andmed salvestatud', type: 'success' })
      }).catch(err => {
        this.showNotification({ message: err.response.data.message, type: 'error' })
      })
    },
    passwordSubmit () {
      this.$http.put('/api/users/me/password', {
        password: this.passwordForm.password,
        passwordRepeat: this.passwordForm.passwordRepeat
      }).then(() => {
        this.loadData()
        this.showNotification({ message: 'Parool vahetatud', type: 'success' })
      }).catch(err => {
        this.showNotification({ message: err.response.data.message, type: 'error' })
      })
    },
    avatarSubmit () {
      if (!this.avatarForm.files) {
        return
      }

      const data = new FormData()
      data.append('file', this.avatarForm.files)

      this.$http.post('/api/users/me/avatar', data, {
        headers: {'Content-Type': 'multipart/form-data' }
      }).then(() => {
        this.loadData()
        this.showNotification({ message: 'Avatar salvestatud', type: 'success' })
      }).catch(err => {
        this.showNotification({ message: err.response.data.message, type: 'error' })
      })
    },
    loadData () {
      this.isDataLoading = true
      this.$http.get('/api/users/me').then(response => {
        this.profile = response.data
      })
      .catch(() => {})
      .then(() => {
        this.isDataLoading = false
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>